import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class UserService {
  async getUsersAll() {
    const users = await fetchWrapper.get(`${ruta}/user`);
    return users;
  }

  async sendUserNuevo(user) {
    const nuevoUser = await fetchWrapper.post(`${ruta}/register`, user);
    return nuevoUser;
  }
  async deleteUser(user) {
    const userEliminado = await fetchWrapper.delete(`${ruta}/user/` + user.id);
    return userEliminado;
  }
  async updatedUser(user) {
    const userActualizado = await fetchWrapper.put(
      `${ruta}/user/` + user.id,
      user
      );
    return userActualizado;
  }
  /*  async showUser(user) {
    const userShow = await fetchWrapper.get(`${ruta}/user/` + user);
    return userShow;
  } */
  async ListarPermisosUsuarios(user) {
    const LisUser = await fetchWrapper.post(
      `${ruta}/listar_permisos_usuario`,
      user
    );
    return LisUser;
  }
  async obtenerDatosResponsableUser(id){
    const user = await fetchWrapper.post(`${ruta}/responsable_sucursales/obtener_datos_usuario`, {'user_id':id});
    return user;
  }

  async actualizarResponsableSucursalesPermisos(datos){
    const user = await fetchWrapper.post(`${ruta}/responsable_sucursales/actualizar_responsable_sucursal_permisos`, datos);
    return user;
  }
  async panel_necesario_auth(datos){
		return await fetchWrapper.post(`${ruta}/user/panel_necesario_auth`, datos);
	}

  async descargarComisionesPDF(datos){
    return await fetchWrapper.postPdf(`${ruta}/user/descargar_comisiones_pdf`, datos);
  }
}