import { fetchWrapper } from '@/helpers';

const ruta = process.env.VUE_APP_ROOT_API;

export default class TipoMovimientos {

	async getTipoMovimientosAll() {
		const tipos_movientos = await fetchWrapper.get(`${ruta}/tipo_movalmacen`);
		return tipos_movientos;
	}
	async getTipoMovimiento(id) {
		return await fetchWrapper.get(`${ruta}/movimientos_almacen/` + id);
	}


	async getTiposMovimientosPorTipo(tipo) {
		//String con el tipo ej: Ingresos, Egresos
		return await fetchWrapper.post(`${ruta}/tipos_movientos/buscar`, tipo);
	}
  async saveMovimientoAlmacen(movimiento) {
    return await fetchWrapper.post(`${ruta}/movimientos_almacen`, movimiento);
  }
	
	async imprimirPDF(datos){
		return await fetchWrapper.postPdf(`${ruta}/movimientos_almacen/imprimir`, datos);
	}
	async exportarExcel(datos){
		return await fetchWrapper.postWithBlob(`${ruta}/movimientos_almacen/exportar`, datos);
	}

	async filtrarTipoMov(datos){
		return await fetchWrapper.post(`${ruta}/movimientos_almacen/filtrar`, datos);
	}

	async imprimirMov(id) {
		return await fetchWrapper.postPdf(`${ruta}/movimientos_almacen/imprimirid/${id}`);
	}


}